<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>

          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.task.status.active') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 0 }"
                     v-on:click="applyStatusFilter(0)" data-toggle="tab">{{ $t('form.task.status.inactive')}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
<!--                <div class="flexbox px-4 py-3 mb-3">-->
<!--                  <div class="d-flex align-items-center">-->
<!--                  </div>-->
<!--                  <div class="btn-group" role="group" aria-label="false">-->
<!--                    <router-link :to="{name: 'ScheduleNew'}" class="btn btn-outline-light  pull-right">-->
<!--                      Novo-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->
                <!--  End Actions -->

                <!-- Table -->
                <custom-vue-table
                  ref="TarifarioLclTable"
                  http-method="get"
                  :api-url="listEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link btn-padding" :title="$t('actions.view')"
                         v-bind:href="mountLink('TariffLclView', props.rowData)">
                        <i class="text-primary rc-Ativo-210 font-20"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
                <!-- END Table -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import TaskLog from '@/views/Schedules/TaskLog.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import CustomVueTable from '@/components/Table/CustomVueTable'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaskService from '@/services/TaskService'
import CustomerService from '@/services/CustomerService'

export default {
  name: 'TariffLclList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tarifario_lcl') + ' - %s'
    }
  },
  data () {
    return {
      customerList: [],
      isLoading: false,
      fullPage: true,
      listEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/tarifario/lcl',
      activeFilterList: {
        customer: null,
        id_status: null
      },
      filter: {},
      sortOrder: [
        { field: 'data_inicio_vigencia', direction: 'desc' },
        { field: 'armador', direction: 'asc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBar,
    Loading,
    FilterContainer,
    MultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.filter.internal_code = this.$route.params.process
    this.filters.filProcesso = this.$route.params.process

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    showModal () {
      this.$modal.show(TaskLog, {}, {
        draggable: false,
        adaptive: true,
        scrollable: true,
        clickToClose: false,
        width: '80%',
        height: 'auto'
      })
    },
    // Delete
    onDelete (task) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir esta tarefa.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(task)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Tarefa excluída com sucesso!')
        }
      })
    },
    async delete (task) {
      let _this = this
      _this.isLoading = true

      await TaskService.deleteTask(task.id).then(task => {
        _this.isLoading = false
        this.$refs.TarifarioLclTable.refresh()
      })
    },
    // Force Send
    onSendNow (task) {
      var _this = this
      _this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja enviar esse tarifário agora.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.sendNow(task)).then((result) => {
        if (result.value) {
          _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
        }
      })
    },
    async sendNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.forceSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // Send Test
    onSendTestNow (task) {
      let _this = this
      _this.sendTestNow(task).then((result) => {
        _this.$alertSwal.toast('Tarifário adicionado na fila de envio!')
      })
    },
    async sendTestNow (task) {
      let _this = this
      _this.isLoading = true
      await TaskService.testSendTask(task.id).then(res => {
        if (res.httpCode === 200 && res.data.status === 'ok') {
          _this.isLoading = false
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id: object.id } }).href
    },
    buildFilters () {
      this.filter.users = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []
      this.filter.employees = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.id_status = null
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filters.status = idStatus
      this.$refs.TarifarioLclTable.refresh()
    },
    // Loader
    applyFilter () {
      this.customerList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.TarifarioLclTable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.filters.filEmployees = []
      this.filters.filUsers = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.TarifarioLclTable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value && value !== 0) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.task.status.active'),
          'color': '#5ec65f'
        },
        {
          'id': 0,
          'label': this.$i18n.t('form.task.status.inactive'),
          'color': '#ababab'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.$router.push({ name: 'ScheduleEdit', params: { id_schedule: row.data.id } })
    }
  },
  computed: {
    activeFilters () {
      return this.filters
    },
    fieldsTable () {
      return [
        {
          name: 'numero_contrato',
          title: this.$i18n.t('form.tarifario_lcl.numero_contrato'),
          sortField: 'numero_contrato',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tipo_operacao',
          title: this.$i18n.t('form.tarifario_lcl.tipo_operacao'),
          sortField: 'tipo_operacao',
          formatter: (value) => this.$util.formatTipoOperacao(value)
        },
        {
          name: 'armador',
          title: this.$i18n.t('form.tarifario_lcl.armador'),
          sortField: 'armador',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'moeda',
          title: this.$i18n.t('form.tarifario_lcl.moeda'),
          sortField: 'moeda',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'data_inicio_vigencia',
          title: this.$i18n.t('form.tarifario_lcl.data_inicio_vigencia'),
          sortField: 'data_inicio_vigencia',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'data_validade',
          title: this.$i18n.t('form.tarifario_lcl.data_validade'),
          sortField: 'data_validade',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'status',
          title: this.$i18n.t('form.task.status.title'),
          sortField: 'u_status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .btn-padding {
    padding: 0.55rem 0.30rem
  }
</style>
